<template>
    <div class="user">
      <img src="../../assets/image/Snipaste21.png" alt="">
    </div>
  </template>
  
  <script>

  export default {
    // components: { CitySelect },
    name: 'User',
  
  }
  </script>
  
  <style lang="less" scoped>
  .user {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    // overflow: hidden;
    
  }
  </style>
  